import React from 'react'
import bannerlarge1 from '../images/bannerlarge1.jpeg'
import bannerlarge2 from '../images/bannerlarge2.jpeg'
import banner1 from '../images/banner1.jpeg'
import banner2 from '../images/banner2.jpeg'
import banner3 from '../images/banner-3.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// Import Swiper styles

import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const Hero = () => {
  return (
    <div className='relative '>
      <div className=' mt-[82px] hidden lg:flex sliderWrapper -z-10 ' >
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-navigation-color": "#000",
            "--swiper-navigation-size": "14px",
            "--swiper-navigation-weight": "700",
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper -z-10  "
        >
          <SwiperSlide>
            <div className="flex justify-center items-center min-h-[60vh] max-h-[80vh] w-full  -z-10">
              <img
                loading="lazy"
                src={banner3}
                alt=""
                className="max-h-[80vh] w-auto object-contain"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=' flex justify-center items-center  w-screen  -z-10' >
              <img loading='lazy' src={banner1} alt='' className='max-h-[80vh] w-auto object-contain' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=' flex justify-center items-center  w-screen   -z-10 ' >
              <img loading='lazy' src={banner2} alt='' className='max-h-[80vh] w-auto object-contain' />
            </div>
          </SwiperSlide>
        </Swiper>

      </div>
      <div className=' mt-[82px] sliderWrapper lg:hidden visible -z-10  '>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "white",
            "--swiper-pagination-bullet-inactive-color": "#999999",
            "--swiper-pagination-bullet-inactive-opacity": "1",
          }}
          pagination={true}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper -z-10 lg
          :hidden block "
        >
          <SwiperSlide>
            <div className='flex justify-center items-center w-full min-h-[40vh] max-h-[50vh]   -z-10 ' >
              <img loading='lazy' src={banner3} alt='' className=' max-h-[50vh] w-auto object-cotain ' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='flex justify-center items-center w-screen h-[50vh] -z-10 ' >
              <img loading='lazy' src={bannerlarge1} alt='' className=' max-h-[50vh] w-full object-cover ' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='flex justify-center items-center w-screen h-[50vh]  -z-10 ' >
              <img loading='lazy' src={bannerlarge2} alt='' className=' max-h-[50vh] w-full object-cover ' />
            </div>
          </SwiperSlide>

        </Swiper>
      </div>

    </div>
  )
}

export default Hero
