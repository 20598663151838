import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import { grey, purple } from '@mui/material/colors';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Country, City } from 'country-state-city';
import { countryToAlpha2 } from "country-to-iso";
import contact from '../images/contact.webp'

import Autocomplete from '@mui/material/Autocomplete';
import emailjs from '@emailjs/browser';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';



const theme = createTheme({
    palette: {
        primary: {
            main: '#6e0d1d',
        },
        secondary: {
            main: '#f44336',
        },
    },
});
const theme1 = createTheme({
    palette: {
        primary: {
            main: '#6e0d1d',
        },
        secondary: {
            main: '#f44336',
        },
    },
});
const steps = ['User Details', 'Booking Details'];

const color = grey[300]

const Conatct = () => {

    const page1 = useRef();
    const page2 = useRef();


    const [username, setUsername] = useState('');
    const [username1, setUsername1] = useState('john');
    const [mobile, setMobile] = useState('');
    const [mobile1, setMobile1] = useState('9191919199');
    const [email, setEmail] = useState('');
    const [email1, setEmail1] = useState('john@gmail.com');
    const [date, setDate] = useState(dayjs(''));
    const [time, setTime] = useState(dayjs(''));
    const [desc, setDesc] = useState('');

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (e) => {

        e.preventDefault()

        console.log("hello world!");

        setActiveStep(0)
        setUsername('')
        setEmail('')
        setMobile('')
        setEmail1('john@gmail.com')
        setUsername1('john')
        setMobile1('9191919191')
        setManufacturer2('')
        setManufacturer3('')
        // console.log('SUCCESS!');
        console.log("dss", username, mobile, email)
        console.log("dss1", username1, mobile1, email1)

        emailjs
            .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
                publicKey: process.env.REACT_APP_PUBLIC_KEY,
            })
            .then(
                () => {
                    
                },
                (error) => {
                    // console.log('FAILED...', error.text);
                },
            );

        setState({ ...state, open: true });
        setActiveStep(0);
        setTimeout(() => {
            setState({ ...state, open: false });
        }, 3000)


    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const form = useRef();

    const sendEmail = (e) => {
   

    };

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const handleNext = () => {

        setMobile1(mobile)
        setUsername1(username)
        setEmail1(email)
       
        if (mobile === '' || username === '' || email === '') {
            console.log("hello")
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep);
            setSkipped(newSkipped);
        }
        else {
            console.log("hello")
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }

    };

    // console.log(form.current)
    console.log(
        'savan',form
    )


    const handleReset = () => {
        setActiveStep(0);
    };

    const [manufacturer2, setManufacturer2] = useState('')
    const [manufacturer3, setManufacturer3] = useState('')

    // const [city, setCity] = useState('')
    const [manufacturer4, setManufacturer4] = useState('')

    const [inputValue2, setInputValue2] = useState('');
    const [inputValue3, setInputValue3] = useState('');


    const [handleScroll, sethandleScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 1000) {
                sethandleScroll(true)
            }
            else {
                sethandleScroll(false)
            }
        })
    }, [])



    return (
        <div className='bg-[#f9f9f9] pb-10 lg:mt-[82px] mt-[60px]'>
            <div className='lg:hidden block   lg:-ml-12  '>
                <img src={contact} alt='' className=' lg:h-[87vh] lg:w-[50vw]  w-screen h-60 object-cover aspect-square' />
            </div>
            <div className='lg:hidden pt-10 flex  flex-wrap  gap-10  '>
                <div className='mx-auto'>

                    <p className='text-[#6e0d1d] text-[30px] text-center'>Production Unit Offices</p>
                    <p className='text-[14px] tracking-wide my-2  '>Get our physical presence on below addresses</p>
                </div>

                <div className='mx-4 w-full   border rounded-xl text-center shadow-lg bg-white text-[#5e5e5e]' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9282.808977268924!2d72.83542772095855!3d21.211161750279825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f75f02fe515%3A0x57c362106c0174ca!2sNKJewels!5e0!3m2!1sen!2sin!4v1707932151983!5m2!1sen!2sin" className='rounded-lg lg:w-[450px] lg:h-[200px] h-[160px] w-full' style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <p className='mx-auto font-bold text-[22px] mt-2 mb-1 text-[#3c3c3c]'>Surat</p>
                    <div className='text-center mb-1 ' style={{ lineHeight: "2.5" }}>

                        {/* <p className='font-semibold '>NKJewels</p> */}
                        <p className='flex px-6  gap-4'>
                            <div className='min-w-7 mt-1'>


                                <svg xmlns="http://www.w3.org/2000/svg" className='' viewBox="0 0 512 512" fill="none">
                                    <path d="M128.632 312.11C123.842 312.132 119.586 315.21 118.107 319.733L71.1455 464.287C68.8245 471.399 74.1834 478.675 81.6984 478.675H430.348C437.897 478.675 443.222 471.399 440.935 464.287L393.939 319.733C392.46 315.187 388.204 312.11 383.392 312.11H319.243C303.695 311.342 303.695 334.968 319.243 334.165H375.336L415.101 456.59H96.9788L136.71 334.165L213.5 333.562L194.969 312.11H128.632Z" fill="#35476B"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M261.5 375L151 249C122.334 197.333 96.6002 83.7997 223 42.9997C268 35.1663 360.6 46.5997 371 155C371.333 176 364.7 226.3 335.5 259.5L270 375H261.5ZM253 126.5C217 123.7 208.667 151 209 165C209 199.8 238.333 205.833 253 204.5C281.4 202.1 288.833 177.166 289 165C289 132.2 265 125.666 253 126.5Z" fill="#E95649" fill-opacity="0.5"></path>
                                    <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                    <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                    <path d="M272.66 188.246C259.405 201.433 238.231 201.462 224.982 188.28C211.726 175.094 211.726 153.988 224.982 140.79C238.237 127.608 259.405 127.637 272.66 140.824C285.944 154.005 285.944 175.037 272.66 188.218V188.246ZM288.378 203.851C310.11 182.244 310.11 146.854 288.378 125.213C266.618 103.596 231.04 103.596 209.32 125.213C187.56 146.854 187.56 182.239 209.32 203.851C231.04 225.469 266.618 225.469 288.378 203.851Z" fill="#35476B"></path>
                                </svg>
                            </div>
                            <p className='text-left '>

                                1st floor, plot no. B-7 Krushnakant compound, Vasta Devadi road, Katargam, opp. Torrent Power, Surat, Gujarat, 395004
                            </p>
                        </p>
                        <p className='flex items-center px-6 gap-4'> <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                            <g clip-path="url(#clip0_740_190)">
                                <path d="M423.965 422.672C431.273 429.985 431.273 441.833 423.965 449.133L403.285 469.813C370.445 502.649 320.137 511.321 278.43 490.879C133.137 419.633 57.7188 306.219 21.0704 232.231C0.472732 190.653 9.33992 140.575 42.1602 107.766L62.379 87.5474C69.6798 80.2388 81.5274 80.2388 88.8282 87.5474L168.727 167.446C176.035 174.743 176.035 186.594 168.727 193.891L134.777 227.84C125.008 237.61 122.758 253.04 130.195 264.692C160.164 311.586 199.973 351.375 246.82 381.317C258.473 388.754 273.902 386.504 283.672 376.735L317.617 342.786C324.918 335.477 336.77 335.477 344.066 342.786L423.965 422.672Z" fill="#E95649" fill-opacity="0.5"></path>
                                <path d="M245.764 94C240.923 94 237 97.9232 237 102.764C237 107.604 240.923 111.528 245.764 111.528C331.071 111.528 400.472 180.932 400.472 266.236C400.472 271.077 404.399 275 409.236 275C414.077 275 418 271.077 418 266.236C418 171.266 340.737 94 245.764 94Z" fill="#35476B" stroke="#35476B" stroke-width="2"></path>
                                <path d="M340.132 269.5C344.202 269.5 347.5 266.199 347.5 262.132C347.5 209.398 304.601 166.5 251.868 166.5C247.798 166.5 244.5 169.801 244.5 173.868C244.5 177.938 247.799 181.237 251.868 181.237C296.472 181.237 332.763 217.526 332.763 262.132C332.763 266.199 336.061 269.5 340.132 269.5Z" fill="#35476B" stroke="#35476B" stroke-width="3"></path>
                                <path d="M274.031 499.86C319.567 522.176 374.348 512.891 410.356 476.883L431.031 456.208C442.258 444.993 442.262 426.825 431.035 415.602L351.145 335.719C339.938 324.497 321.766 324.481 310.547 335.715L276.602 369.665C269.953 376.313 259.692 377.668 252.207 372.891C240.004 365.09 228.133 356.489 216.926 347.325C212.649 343.829 206.352 344.461 202.856 348.735C199.36 353.012 199.992 359.313 204.266 362.805C216.074 372.461 228.578 381.524 241.442 389.743C256.774 399.536 277.508 397.04 290.742 383.805L324.695 349.852C328.09 346.454 333.59 346.446 337 349.856L416.895 429.743C420.297 433.149 420.305 438.657 416.895 442.059L396.215 462.743C366.219 492.739 320.656 500.438 282.832 481.903C144.266 413.954 68.8751 306.211 30.0314 227.79C11.4494 190.286 19.1642 144.895 49.2306 114.836L69.4572 94.6138C72.8478 91.2153 78.3517 91.2036 81.7579 94.6177L161.66 174.516C165.043 177.895 165.078 183.403 161.652 186.821L127.707 220.77C114.473 234.004 111.977 254.739 121.77 270.075C127.539 279.102 133.758 288.008 140.258 296.543C143.602 300.938 149.875 301.786 154.27 298.442C158.664 295.098 159.516 288.825 156.168 284.426C150.004 276.329 144.098 267.875 138.621 259.309C133.844 251.821 135.199 241.559 141.848 234.911L175.789 200.969C187.012 189.762 187.024 171.586 175.797 160.372L95.9025 80.481C84.6993 69.2583 66.5236 69.2427 55.3087 80.4771L35.09 100.696C-1.05065 136.825 -10.285 191.469 12.1095 236.668C52.3322 317.868 130.418 429.438 274.031 499.86Z" fill="#35476B"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_740_190">
                                    <rect width="512" height="512" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>0261-3518296</p>
                        <p className='flex items-center px-6  gap-4'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                            <path d="M456.048 120.077H52.1504L258.15 298.88L456.048 120.077Z" fill="#E95649" fill-opacity="0.5"></path>
                            <path d="M49.8866 369.827C49.2941 367.457 48.7015 365.087 48.7015 362.717V314.851C48.7015 308.333 43.3687 303 36.8508 303C30.3328 303 25 308.333 25 314.851V362.717C25 388.789 46.3314 410.12 72.4031 410.12H439.777C465.848 410.12 487.18 388.789 487.18 362.717V149.403C487.18 143.216 485.979 137.296 483.798 131.865L463.478 149.403V362.717C463.478 365.087 462.886 367.457 462.293 369.827C461.345 381.219 450.047 385.239 444.517 385.826C443.332 386.418 441.554 386.418 439.777 386.418H72.4031C70.6254 386.418 68.8478 386.418 67.6628 385.826C54.6121 385.352 50.3742 374.963 49.8866 369.827Z" fill="#35476B"></path>
                            <path d="M25 149.403V252.415C25 258.933 30.3328 264.266 36.8508 264.266C43.3687 264.266 48.7015 258.933 48.7015 252.415V149.403L28.402 131.815C26.2086 137.26 25 143.197 25 149.403Z" fill="#35476B"></path>
                            <path d="M439.777 102H72.4031C55.3622 102 40.3464 111.113 31.9886 124.706L50.4143 141.487L232.981 299.315C239.499 305.241 247.794 308.203 256.09 308.203C264.268 308.203 272.447 305.324 278.922 299.564L341.624 245C346.923 238.6 346 232.75 343 229.5C337 223 329 225.333 326.25 227.5L263.119 281.423C258.5 284.494 252.905 284.335 248.387 280.947L67.6628 126.294C68.8478 125.702 70.6254 125.702 72.4031 125.702H439.777C441.554 125.702 443.332 125.702 444.517 126.294L369 190.917C366.5 193.111 362.7 199.3 367.5 206.5C369.715 209.333 376.032 213.7 383.573 208.5L461.256 140.909L480.352 124.969C472.027 111.231 456.927 102 439.777 102Z" fill="#35476B"></path>
                        </svg>nkjewels330@gmail.com</p>
                    </div>
                </div>
                <div className='mx-4 w-full   border rounded-xl text-center shadow-lg bg-white text-[#5e5e5e]' style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }} >
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.7640341304455!2d76.20834409999999!3d10.519242499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ee47393193b3%3A0xdbc234e10f83b219!2sRowdha%20Towers!5e0!3m2!1sen!2sin!4v1707746006712!5m2!1sen!2sin" className='rounded-lg lg:w-[450px] lg:h-[200px] h-[160px] w-full' style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    <p className='mx-auto font-bold text-[22px] mt-2 mb-1 text-[#3c3c3c]'>Thrissur</p>
                    <div className='text-center mb-1 ' style={{ lineHeight: "2.5" }}>

                        {/* <p className='font-semibold '>NKJewels</p> */}
                        <p className='flex px-6  gap-4'>
                            <div className='min-w-7 mt-1'>


                                <svg xmlns="http://www.w3.org/2000/svg" className='' viewBox="0 0 512 512" fill="none">
                                    <path d="M128.632 312.11C123.842 312.132 119.586 315.21 118.107 319.733L71.1455 464.287C68.8245 471.399 74.1834 478.675 81.6984 478.675H430.348C437.897 478.675 443.222 471.399 440.935 464.287L393.939 319.733C392.46 315.187 388.204 312.11 383.392 312.11H319.243C303.695 311.342 303.695 334.968 319.243 334.165H375.336L415.101 456.59H96.9788L136.71 334.165L213.5 333.562L194.969 312.11H128.632Z" fill="#35476B"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M261.5 375L151 249C122.334 197.333 96.6002 83.7997 223 42.9997C268 35.1663 360.6 46.5997 371 155C371.333 176 364.7 226.3 335.5 259.5L270 375H261.5ZM253 126.5C217 123.7 208.667 151 209 165C209 199.8 238.333 205.833 253 204.5C281.4 202.1 288.833 177.166 289 165C289 132.2 265 125.666 253 126.5Z" fill="#E95649" fill-opacity="0.5"></path>
                                    <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                    <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                    <path d="M272.66 188.246C259.405 201.433 238.231 201.462 224.982 188.28C211.726 175.094 211.726 153.988 224.982 140.79C238.237 127.608 259.405 127.637 272.66 140.824C285.944 154.005 285.944 175.037 272.66 188.218V188.246ZM288.378 203.851C310.11 182.244 310.11 146.854 288.378 125.213C266.618 103.596 231.04 103.596 209.32 125.213C187.56 146.854 187.56 182.239 209.32 203.851C231.04 225.469 266.618 225.469 288.378 203.851Z" fill="#35476B"></path>
                                </svg>
                            </div>
                            <p className='text-left '>

                                12/651/73 (38/222/73)-Rowdha Tower, 3rd Floor, Poothole Road, Near Railway Over Bridge, Thrissur, Kerala-680 004
                            </p>
                        </p>
                        <p className='flex items-center px-6 gap-4'> <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                            <g clip-path="url(#clip0_740_190)">
                                <path d="M423.965 422.672C431.273 429.985 431.273 441.833 423.965 449.133L403.285 469.813C370.445 502.649 320.137 511.321 278.43 490.879C133.137 419.633 57.7188 306.219 21.0704 232.231C0.472732 190.653 9.33992 140.575 42.1602 107.766L62.379 87.5474C69.6798 80.2388 81.5274 80.2388 88.8282 87.5474L168.727 167.446C176.035 174.743 176.035 186.594 168.727 193.891L134.777 227.84C125.008 237.61 122.758 253.04 130.195 264.692C160.164 311.586 199.973 351.375 246.82 381.317C258.473 388.754 273.902 386.504 283.672 376.735L317.617 342.786C324.918 335.477 336.77 335.477 344.066 342.786L423.965 422.672Z" fill="#E95649" fill-opacity="0.5"></path>
                                <path d="M245.764 94C240.923 94 237 97.9232 237 102.764C237 107.604 240.923 111.528 245.764 111.528C331.071 111.528 400.472 180.932 400.472 266.236C400.472 271.077 404.399 275 409.236 275C414.077 275 418 271.077 418 266.236C418 171.266 340.737 94 245.764 94Z" fill="#35476B" stroke="#35476B" stroke-width="2"></path>
                                <path d="M340.132 269.5C344.202 269.5 347.5 266.199 347.5 262.132C347.5 209.398 304.601 166.5 251.868 166.5C247.798 166.5 244.5 169.801 244.5 173.868C244.5 177.938 247.799 181.237 251.868 181.237C296.472 181.237 332.763 217.526 332.763 262.132C332.763 266.199 336.061 269.5 340.132 269.5Z" fill="#35476B" stroke="#35476B" stroke-width="3"></path>
                                <path d="M274.031 499.86C319.567 522.176 374.348 512.891 410.356 476.883L431.031 456.208C442.258 444.993 442.262 426.825 431.035 415.602L351.145 335.719C339.938 324.497 321.766 324.481 310.547 335.715L276.602 369.665C269.953 376.313 259.692 377.668 252.207 372.891C240.004 365.09 228.133 356.489 216.926 347.325C212.649 343.829 206.352 344.461 202.856 348.735C199.36 353.012 199.992 359.313 204.266 362.805C216.074 372.461 228.578 381.524 241.442 389.743C256.774 399.536 277.508 397.04 290.742 383.805L324.695 349.852C328.09 346.454 333.59 346.446 337 349.856L416.895 429.743C420.297 433.149 420.305 438.657 416.895 442.059L396.215 462.743C366.219 492.739 320.656 500.438 282.832 481.903C144.266 413.954 68.8751 306.211 30.0314 227.79C11.4494 190.286 19.1642 144.895 49.2306 114.836L69.4572 94.6138C72.8478 91.2153 78.3517 91.2036 81.7579 94.6177L161.66 174.516C165.043 177.895 165.078 183.403 161.652 186.821L127.707 220.77C114.473 234.004 111.977 254.739 121.77 270.075C127.539 279.102 133.758 288.008 140.258 296.543C143.602 300.938 149.875 301.786 154.27 298.442C158.664 295.098 159.516 288.825 156.168 284.426C150.004 276.329 144.098 267.875 138.621 259.309C133.844 251.821 135.199 241.559 141.848 234.911L175.789 200.969C187.012 189.762 187.024 171.586 175.797 160.372L95.9025 80.481C84.6993 69.2583 66.5236 69.2427 55.3087 80.4771L35.09 100.696C-1.05065 136.825 -10.285 191.469 12.1095 236.668C52.3322 317.868 130.418 429.438 274.031 499.86Z" fill="#35476B"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_740_190">
                                    <rect width="512" height="512" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>+91 9978366966</p>
                        <p className='flex items-center px-6  gap-4'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                            <path d="M456.048 120.077H52.1504L258.15 298.88L456.048 120.077Z" fill="#E95649" fill-opacity="0.5"></path>
                            <path d="M49.8866 369.827C49.2941 367.457 48.7015 365.087 48.7015 362.717V314.851C48.7015 308.333 43.3687 303 36.8508 303C30.3328 303 25 308.333 25 314.851V362.717C25 388.789 46.3314 410.12 72.4031 410.12H439.777C465.848 410.12 487.18 388.789 487.18 362.717V149.403C487.18 143.216 485.979 137.296 483.798 131.865L463.478 149.403V362.717C463.478 365.087 462.886 367.457 462.293 369.827C461.345 381.219 450.047 385.239 444.517 385.826C443.332 386.418 441.554 386.418 439.777 386.418H72.4031C70.6254 386.418 68.8478 386.418 67.6628 385.826C54.6121 385.352 50.3742 374.963 49.8866 369.827Z" fill="#35476B"></path>
                            <path d="M25 149.403V252.415C25 258.933 30.3328 264.266 36.8508 264.266C43.3687 264.266 48.7015 258.933 48.7015 252.415V149.403L28.402 131.815C26.2086 137.26 25 143.197 25 149.403Z" fill="#35476B"></path>
                            <path d="M439.777 102H72.4031C55.3622 102 40.3464 111.113 31.9886 124.706L50.4143 141.487L232.981 299.315C239.499 305.241 247.794 308.203 256.09 308.203C264.268 308.203 272.447 305.324 278.922 299.564L341.624 245C346.923 238.6 346 232.75 343 229.5C337 223 329 225.333 326.25 227.5L263.119 281.423C258.5 284.494 252.905 284.335 248.387 280.947L67.6628 126.294C68.8478 125.702 70.6254 125.702 72.4031 125.702H439.777C441.554 125.702 443.332 125.702 444.517 126.294L369 190.917C366.5 193.111 362.7 199.3 367.5 206.5C369.715 209.333 376.032 213.7 383.573 208.5L461.256 140.909L480.352 124.969C472.027 111.231 456.927 102 439.777 102Z" fill="#35476B"></path>
                        </svg>nkjewels330@gmail.com</p>
                    </div>
                </div>


            </div>
            <div className='   lg:flex  -z-20 gap-10    overflow-x-hidden items-center w-screen lg:h-[87vh]   text-[#101926] '>.
                <div className='lg:flex hidden   lg:-ml-12  '>
                    <img src={contact} alt='' className=' lg:h-[87vh] lg:w-[50vw]  w-full h-52 object-cover aspect-square' />
                </div>
                {/* form */}
                <div className=' flex justify-center items-center lg:h-[87vh] lg:w-[50%] mt-10 sm:mt-0 '>
                    <div>
                        <p className='text-3xl  font-normal text-center mt-4 mb-8 text-[#6e0d1d] tracking-wide'>Book An Appoinment </p>
                        <ThemeProvider theme={theme}>

                            <Stepper color='' activeStep={activeStep} className='lg:mb-4 mb-6' sx={{ color: color }}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepOptional(index)) {
                                        labelProps.optional = (
                                            <Typography variant="caption"></Typography>
                                        );
                                    }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </ThemeProvider>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                {/* <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box> */}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>



                                    {/* <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button> */}
                                </Box>
                            </React.Fragment>
                        )}


                        <form ref={form} onSubmit={sendEmail}>
                            {activeStep === 0 ?
                                <div className='flex flex-col lg:gap-4 gap-6' ref={page1}>

                                    <ThemeProvider theme={theme}>
                                        {username1 === '' ? <TextField id="outlined-basic" color='' helperText="UserName required!" error className='lg:w-96 w-[354px]' size='medium' sx={{ "& fieldset": {} }} label="Your Name*" variant="outlined" name="name" onChange={(event) => setUsername(event.target.value)} /> :
                                            <TextField id="outlined-basic" color='' className='lg:w-96 w-[354px]' size='medium' sx={{ "& fieldset": {} }} label="Your Name*" variant="outlined" name="name" onChange={(event) => setUsername(event.target.value)} />
                                        }
                                        {mobile1 === '' ? <TextField id="outlined-basic" helperText="Mobile no. required!" error className='lg:w-96 w-[354px]' label="Mobile Number*" variant="outlined" name="phone" onChange={(event) => setMobile(event.target.value)} /> : <TextField id="outlined-basic" className='lg:w-96 w-[354px]' label="Mobile Number*" variant="outlined" name="phone" onChange={(event) => setMobile(event.target.value)} />

                                        }
                                        {
                                            email1 === '' ? <TextField id="outlined-basic" helperText="Email required!" error className='lg:w-96 w-[354px]' label="Email*" variant="outlined" name="email" onChange={(event) => setEmail(event.target.value)} /> : <TextField id="outlined-basic" className='lg:w-96 w-[354px]' label="Email*" variant="outlined" name="email" onChange={(event) => setEmail(event.target.value)} />
                                        }


                                    </ThemeProvider>
                                    <div className={`bg-white z-20 shadow-t-3xl py-[20px] px-[20px] sm:bg-none sm:shadow-none sm:py-0 sm:px-0 ${handleScroll && 'fixed bottom-0'} left-0 w-full  sm:relative lg:relative `}>
                                        <p className='disabled:opacity-75  cursor-pointer bg-[#6e0d1d]/80 z-20 text-center py-3 text-xl rounded-md font-light text-white  w-full' onClick={handleNext} >
                                            Next</p>
                                    </div>

                                </div>

                                :
                                <div className='flex flex-col lg:gap-4 gap-6 ' ref={page2}>


                                    <ThemeProvider theme={theme}>
                                        <div className='hidden'>
                                            <input type="text" name="name" value={username} />
                                            <input type="email" name="email" value={email} />
                                            <input type="number" name="phone" value={mobile} />

                                        </div>
                                        <div className='flex gap-2 lg:w-96 w-[354px] lg:max-w-[384px]'>

                                            <Autocomplete
                                                disablePortal
                                                value={manufacturer3}
                                                onChange={(event, newValue) => {
                                                    setManufacturer4(countryToAlpha2(newValue));
                                                    setManufacturer3(newValue);
                                                }}
                                                inputValue={inputValue3}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue3(newInputValue);
                                                }}
                                                id="combo-box-demo"
                                                options={Country.getAllCountries().map((option) =>
                                                    option.name
                                                )}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Select country" name='country' />}
                                            />
                                            <Autocomplete
                                                disablePortal
                                                value={manufacturer2}
                                                onChange={(event, newValue) => {
                                                    setManufacturer2(newValue);
                                                }}
                                                inputValue={inputValue2}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue2(newInputValue);
                                                }}
                                                id="combo-box-demo"
                                                options={City.getCitiesOfCountry(manufacturer4).map((option) => option.name)}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Select city" name='city' />}
                                            />
                                        </div>

                                        <div className='flex gap-2  lg:w-96 w-[354px] lg:max-w-[384px]'>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DemoItem label="">
                                                        <DatePicker label='Pick date' name='date' />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['TimePicker']}>
                                                    <DemoItem label="">
                                                        <TimePicker label='Pick time' name='time' />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>

                                        </div>
                                        <TextField id="outlined-basic" className='lg:w-96 w-[354px]' label="Jewellery that you are looking for?" name='description' variant="outlined" />

                                    </ThemeProvider>
                                    <div className={`bg-white z-20 shadow-t-3xl py-[20px] px-[20px] sm:bg-none sm:shadow-none sm:py-0 sm:px-0 ${handleScroll && 'fixed bottom-0'} left-0 w-full  sm:relative lg:relative `}>
                                        <button className='bg-[#6e0d1d]/80 z-20 py-3 text-xl rounded-md font-light text-white  w-full' onClick={handleClick}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            }



                        </form>
                    </div>

                </div>
            </div>
            <ThemeProvider theme={theme1}>

                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    message="Details submitted  successfully"
                    key={vertical + horizontal}
                    onClose={handleClose}
                    ContentProps={{
                        sx: {
                            background: "white",
                            color: "black",
                            borderBottom: "4px solid green",

                        }
                    }}
                />
            </ThemeProvider>



            <div className='lg:block pt-10 hidden '>
                <div className='mx-auto '>

                    <p className='text-[#6e0d1d] text-[30px] text-center'>Production Unit Offices</p>
                    <p className='text-[14px] tracking-wide p-2  text-center '>Get our physical presence on below addresses</p>
                </div>

                <div className='flex flex-wrap px-4 my-6 justify-center gap-20 items-center'>
                    <div className='mx-auto  max-w-[450px] border rounded-lg text-center bg-white shadow-lg text-[#5e5e5e]' >




                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9282.808977268924!2d72.83542772095855!3d21.211161750279825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f75f02fe515%3A0x57c362106c0174ca!2sNKJewels!5e0!3m2!1sen!2sin!4v1707932151983!5m2!1sen!2sin" className='rounded-lg lg:w-[450px] lg:h-[200px] h-[160px] w-full' style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        <p className='mx-auto font-bold text-[22px] mb-1 mt-2  text-[#3c3c3c]'>Surat</p>
                        <div className='text-center mb-1 ' style={{ lineHeight: "2.5" }}>

                            {/* <p className='font-semibold '>NKJewels</p> */}
                            <p className='flex px-6  gap-4'>
                                <div className='min-w-7 mt-1'>


                                    <svg xmlns="http://www.w3.org/2000/svg" className='' viewBox="0 0 512 512" fill="none">
                                        <path d="M128.632 312.11C123.842 312.132 119.586 315.21 118.107 319.733L71.1455 464.287C68.8245 471.399 74.1834 478.675 81.6984 478.675H430.348C437.897 478.675 443.222 471.399 440.935 464.287L393.939 319.733C392.46 315.187 388.204 312.11 383.392 312.11H319.243C303.695 311.342 303.695 334.968 319.243 334.165H375.336L415.101 456.59H96.9788L136.71 334.165L213.5 333.562L194.969 312.11H128.632Z" fill="#35476B"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M261.5 375L151 249C122.334 197.333 96.6002 83.7997 223 42.9997C268 35.1663 360.6 46.5997 371 155C371.333 176 364.7 226.3 335.5 259.5L270 375H261.5ZM253 126.5C217 123.7 208.667 151 209 165C209 199.8 238.333 205.833 253 204.5C281.4 202.1 288.833 177.166 289 165C289 132.2 265 125.666 253 126.5Z" fill="#E95649" fill-opacity="0.5"></path>
                                        <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                        <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                        <path d="M272.66 188.246C259.405 201.433 238.231 201.462 224.982 188.28C211.726 175.094 211.726 153.988 224.982 140.79C238.237 127.608 259.405 127.637 272.66 140.824C285.944 154.005 285.944 175.037 272.66 188.218V188.246ZM288.378 203.851C310.11 182.244 310.11 146.854 288.378 125.213C266.618 103.596 231.04 103.596 209.32 125.213C187.56 146.854 187.56 182.239 209.32 203.851C231.04 225.469 266.618 225.469 288.378 203.851Z" fill="#35476B"></path>
                                    </svg>
                                </div>
                                <p className='text-left '>

                                    1st floor, plot no. B-7 Krushnakant compound, Vasta Devadi road, Katargam, opp. Torrent Power, Surat, Gujarat, 395004
                                </p>
                            </p>
                            <p className='flex items-center px-6 gap-4'> <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                                <g clip-path="url(#clip0_740_190)">
                                    <path d="M423.965 422.672C431.273 429.985 431.273 441.833 423.965 449.133L403.285 469.813C370.445 502.649 320.137 511.321 278.43 490.879C133.137 419.633 57.7188 306.219 21.0704 232.231C0.472732 190.653 9.33992 140.575 42.1602 107.766L62.379 87.5474C69.6798 80.2388 81.5274 80.2388 88.8282 87.5474L168.727 167.446C176.035 174.743 176.035 186.594 168.727 193.891L134.777 227.84C125.008 237.61 122.758 253.04 130.195 264.692C160.164 311.586 199.973 351.375 246.82 381.317C258.473 388.754 273.902 386.504 283.672 376.735L317.617 342.786C324.918 335.477 336.77 335.477 344.066 342.786L423.965 422.672Z" fill="#E95649" fill-opacity="0.5"></path>
                                    <path d="M245.764 94C240.923 94 237 97.9232 237 102.764C237 107.604 240.923 111.528 245.764 111.528C331.071 111.528 400.472 180.932 400.472 266.236C400.472 271.077 404.399 275 409.236 275C414.077 275 418 271.077 418 266.236C418 171.266 340.737 94 245.764 94Z" fill="#35476B" stroke="#35476B" stroke-width="2"></path>
                                    <path d="M340.132 269.5C344.202 269.5 347.5 266.199 347.5 262.132C347.5 209.398 304.601 166.5 251.868 166.5C247.798 166.5 244.5 169.801 244.5 173.868C244.5 177.938 247.799 181.237 251.868 181.237C296.472 181.237 332.763 217.526 332.763 262.132C332.763 266.199 336.061 269.5 340.132 269.5Z" fill="#35476B" stroke="#35476B" stroke-width="3"></path>
                                    <path d="M274.031 499.86C319.567 522.176 374.348 512.891 410.356 476.883L431.031 456.208C442.258 444.993 442.262 426.825 431.035 415.602L351.145 335.719C339.938 324.497 321.766 324.481 310.547 335.715L276.602 369.665C269.953 376.313 259.692 377.668 252.207 372.891C240.004 365.09 228.133 356.489 216.926 347.325C212.649 343.829 206.352 344.461 202.856 348.735C199.36 353.012 199.992 359.313 204.266 362.805C216.074 372.461 228.578 381.524 241.442 389.743C256.774 399.536 277.508 397.04 290.742 383.805L324.695 349.852C328.09 346.454 333.59 346.446 337 349.856L416.895 429.743C420.297 433.149 420.305 438.657 416.895 442.059L396.215 462.743C366.219 492.739 320.656 500.438 282.832 481.903C144.266 413.954 68.8751 306.211 30.0314 227.79C11.4494 190.286 19.1642 144.895 49.2306 114.836L69.4572 94.6138C72.8478 91.2153 78.3517 91.2036 81.7579 94.6177L161.66 174.516C165.043 177.895 165.078 183.403 161.652 186.821L127.707 220.77C114.473 234.004 111.977 254.739 121.77 270.075C127.539 279.102 133.758 288.008 140.258 296.543C143.602 300.938 149.875 301.786 154.27 298.442C158.664 295.098 159.516 288.825 156.168 284.426C150.004 276.329 144.098 267.875 138.621 259.309C133.844 251.821 135.199 241.559 141.848 234.911L175.789 200.969C187.012 189.762 187.024 171.586 175.797 160.372L95.9025 80.481C84.6993 69.2583 66.5236 69.2427 55.3087 80.4771L35.09 100.696C-1.05065 136.825 -10.285 191.469 12.1095 236.668C52.3322 317.868 130.418 429.438 274.031 499.86Z" fill="#35476B"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_740_190">
                                        <rect width="512" height="512" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>0261-3518296</p>
                            <p className='flex items-center px-6  gap-4'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                                <path d="M456.048 120.077H52.1504L258.15 298.88L456.048 120.077Z" fill="#E95649" fill-opacity="0.5"></path>
                                <path d="M49.8866 369.827C49.2941 367.457 48.7015 365.087 48.7015 362.717V314.851C48.7015 308.333 43.3687 303 36.8508 303C30.3328 303 25 308.333 25 314.851V362.717C25 388.789 46.3314 410.12 72.4031 410.12H439.777C465.848 410.12 487.18 388.789 487.18 362.717V149.403C487.18 143.216 485.979 137.296 483.798 131.865L463.478 149.403V362.717C463.478 365.087 462.886 367.457 462.293 369.827C461.345 381.219 450.047 385.239 444.517 385.826C443.332 386.418 441.554 386.418 439.777 386.418H72.4031C70.6254 386.418 68.8478 386.418 67.6628 385.826C54.6121 385.352 50.3742 374.963 49.8866 369.827Z" fill="#35476B"></path>
                                <path d="M25 149.403V252.415C25 258.933 30.3328 264.266 36.8508 264.266C43.3687 264.266 48.7015 258.933 48.7015 252.415V149.403L28.402 131.815C26.2086 137.26 25 143.197 25 149.403Z" fill="#35476B"></path>
                                <path d="M439.777 102H72.4031C55.3622 102 40.3464 111.113 31.9886 124.706L50.4143 141.487L232.981 299.315C239.499 305.241 247.794 308.203 256.09 308.203C264.268 308.203 272.447 305.324 278.922 299.564L341.624 245C346.923 238.6 346 232.75 343 229.5C337 223 329 225.333 326.25 227.5L263.119 281.423C258.5 284.494 252.905 284.335 248.387 280.947L67.6628 126.294C68.8478 125.702 70.6254 125.702 72.4031 125.702H439.777C441.554 125.702 443.332 125.702 444.517 126.294L369 190.917C366.5 193.111 362.7 199.3 367.5 206.5C369.715 209.333 376.032 213.7 383.573 208.5L461.256 140.909L480.352 124.969C472.027 111.231 456.927 102 439.777 102Z" fill="#35476B"></path>
                            </svg>nkjewels330@gmail.com</p>
                        </div>
                    </div>
                    <div className='mx-auto  max-w-[450px] border rounded-lg text-center bg-white shadow-lg text-[#5e5e5e]' >

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.7640341304455!2d76.20834409999999!3d10.519242499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ee47393193b3%3A0xdbc234e10f83b219!2sRowdha%20Towers!5e0!3m2!1sen!2sin!4v1707746006712!5m2!1sen!2sin" className='rounded-lg lg:w-[450px] lg:h-[200px] h-[160px] w-full' style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        <p className='mx-auto font-bold text-[22px] mb-1 mt-2  text-[#3c3c3c]'>Thrissur</p>
                        <div className='text-center mb-1 ' style={{ lineHeight: "2.5" }}>

                            {/* <p className='font-semibold '>NKJewels</p> */}
                            <p className='flex px-6  gap-4'>
                                <div className='min-w-7 mt-1'>


                                    <svg xmlns="http://www.w3.org/2000/svg" className='' viewBox="0 0 512 512" fill="none">
                                        <path d="M128.632 312.11C123.842 312.132 119.586 315.21 118.107 319.733L71.1455 464.287C68.8245 471.399 74.1834 478.675 81.6984 478.675H430.348C437.897 478.675 443.222 471.399 440.935 464.287L393.939 319.733C392.46 315.187 388.204 312.11 383.392 312.11H319.243C303.695 311.342 303.695 334.968 319.243 334.165H375.336L415.101 456.59H96.9788L136.71 334.165L213.5 333.562L194.969 312.11H128.632Z" fill="#35476B"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M261.5 375L151 249C122.334 197.333 96.6002 83.7997 223 42.9997C268 35.1663 360.6 46.5997 371 155C371.333 176 364.7 226.3 335.5 259.5L270 375H261.5ZM253 126.5C217 123.7 208.667 151 209 165C209 199.8 238.333 205.833 253 204.5C281.4 202.1 288.833 177.166 289 165C289 132.2 265 125.666 253 126.5Z" fill="#E95649" fill-opacity="0.5"></path>
                                        <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                        <path d="M249.121 47.0843C313.143 47.0843 365.1 98.6996 365.1 162.387C365.1 187.139 352.462 233.708 329.962 259.82C322.053 269 324.265 274.969 327.286 277.998C327.391 278.102 327.494 278.202 327.597 278.296C333.658 283.093 339.5 282.5 342.295 279.4L346.731 274.262C346.747 274.233 346.763 274.203 346.779 274.174C373.71 242.987 387.27 193.01 387.27 162.381C387.275 86.7301 325.181 25 249.121 25C173.06 25 111 86.7301 111 162.381C111 193.01 122.56 233.987 149.457 265.174V265.208L236.844 366.373H217.342C211.341 366.259 206.38 371.072 206.38 377.074C206.38 383.076 211.341 387.928 217.342 387.809H292.83C298.866 387.928 303.827 383.076 303.827 377.074C303.827 371.072 298.861 366.265 292.83 366.373H265.097L166.313 250.849C143.785 224.737 133.147 187.167 133.147 162.387C133.147 98.6996 185.098 47.0843 249.121 47.0843Z" fill="#35476B"></path>
                                        <path d="M272.66 188.246C259.405 201.433 238.231 201.462 224.982 188.28C211.726 175.094 211.726 153.988 224.982 140.79C238.237 127.608 259.405 127.637 272.66 140.824C285.944 154.005 285.944 175.037 272.66 188.218V188.246ZM288.378 203.851C310.11 182.244 310.11 146.854 288.378 125.213C266.618 103.596 231.04 103.596 209.32 125.213C187.56 146.854 187.56 182.239 209.32 203.851C231.04 225.469 266.618 225.469 288.378 203.851Z" fill="#35476B"></path>
                                    </svg>
                                </div>
                                <p className='text-left '>

                                    12/651/73 (38/222/73)-Rowdha Tower, 3rd Floor, Poothole Road, Near Railway Over Bridge, Thrissur, Kerala-680 004
                                </p>
                            </p>
                            <p className='flex items-center px-6 gap-4'> <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                                <g clip-path="url(#clip0_740_190)">
                                    <path d="M423.965 422.672C431.273 429.985 431.273 441.833 423.965 449.133L403.285 469.813C370.445 502.649 320.137 511.321 278.43 490.879C133.137 419.633 57.7188 306.219 21.0704 232.231C0.472732 190.653 9.33992 140.575 42.1602 107.766L62.379 87.5474C69.6798 80.2388 81.5274 80.2388 88.8282 87.5474L168.727 167.446C176.035 174.743 176.035 186.594 168.727 193.891L134.777 227.84C125.008 237.61 122.758 253.04 130.195 264.692C160.164 311.586 199.973 351.375 246.82 381.317C258.473 388.754 273.902 386.504 283.672 376.735L317.617 342.786C324.918 335.477 336.77 335.477 344.066 342.786L423.965 422.672Z" fill="#E95649" fill-opacity="0.5"></path>
                                    <path d="M245.764 94C240.923 94 237 97.9232 237 102.764C237 107.604 240.923 111.528 245.764 111.528C331.071 111.528 400.472 180.932 400.472 266.236C400.472 271.077 404.399 275 409.236 275C414.077 275 418 271.077 418 266.236C418 171.266 340.737 94 245.764 94Z" fill="#35476B" stroke="#35476B" stroke-width="2"></path>
                                    <path d="M340.132 269.5C344.202 269.5 347.5 266.199 347.5 262.132C347.5 209.398 304.601 166.5 251.868 166.5C247.798 166.5 244.5 169.801 244.5 173.868C244.5 177.938 247.799 181.237 251.868 181.237C296.472 181.237 332.763 217.526 332.763 262.132C332.763 266.199 336.061 269.5 340.132 269.5Z" fill="#35476B" stroke="#35476B" stroke-width="3"></path>
                                    <path d="M274.031 499.86C319.567 522.176 374.348 512.891 410.356 476.883L431.031 456.208C442.258 444.993 442.262 426.825 431.035 415.602L351.145 335.719C339.938 324.497 321.766 324.481 310.547 335.715L276.602 369.665C269.953 376.313 259.692 377.668 252.207 372.891C240.004 365.09 228.133 356.489 216.926 347.325C212.649 343.829 206.352 344.461 202.856 348.735C199.36 353.012 199.992 359.313 204.266 362.805C216.074 372.461 228.578 381.524 241.442 389.743C256.774 399.536 277.508 397.04 290.742 383.805L324.695 349.852C328.09 346.454 333.59 346.446 337 349.856L416.895 429.743C420.297 433.149 420.305 438.657 416.895 442.059L396.215 462.743C366.219 492.739 320.656 500.438 282.832 481.903C144.266 413.954 68.8751 306.211 30.0314 227.79C11.4494 190.286 19.1642 144.895 49.2306 114.836L69.4572 94.6138C72.8478 91.2153 78.3517 91.2036 81.7579 94.6177L161.66 174.516C165.043 177.895 165.078 183.403 161.652 186.821L127.707 220.77C114.473 234.004 111.977 254.739 121.77 270.075C127.539 279.102 133.758 288.008 140.258 296.543C143.602 300.938 149.875 301.786 154.27 298.442C158.664 295.098 159.516 288.825 156.168 284.426C150.004 276.329 144.098 267.875 138.621 259.309C133.844 251.821 135.199 241.559 141.848 234.911L175.789 200.969C187.012 189.762 187.024 171.586 175.797 160.372L95.9025 80.481C84.6993 69.2583 66.5236 69.2427 55.3087 80.4771L35.09 100.696C-1.05065 136.825 -10.285 191.469 12.1095 236.668C52.3322 317.868 130.418 429.438 274.031 499.86Z" fill="#35476B"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_740_190">
                                        <rect width="512" height="512" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>+91 9978366966</p>
                            <p className='flex items-center px-6  gap-4'><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512" fill="none">
                                <path d="M456.048 120.077H52.1504L258.15 298.88L456.048 120.077Z" fill="#E95649" fill-opacity="0.5"></path>
                                <path d="M49.8866 369.827C49.2941 367.457 48.7015 365.087 48.7015 362.717V314.851C48.7015 308.333 43.3687 303 36.8508 303C30.3328 303 25 308.333 25 314.851V362.717C25 388.789 46.3314 410.12 72.4031 410.12H439.777C465.848 410.12 487.18 388.789 487.18 362.717V149.403C487.18 143.216 485.979 137.296 483.798 131.865L463.478 149.403V362.717C463.478 365.087 462.886 367.457 462.293 369.827C461.345 381.219 450.047 385.239 444.517 385.826C443.332 386.418 441.554 386.418 439.777 386.418H72.4031C70.6254 386.418 68.8478 386.418 67.6628 385.826C54.6121 385.352 50.3742 374.963 49.8866 369.827Z" fill="#35476B"></path>
                                <path d="M25 149.403V252.415C25 258.933 30.3328 264.266 36.8508 264.266C43.3687 264.266 48.7015 258.933 48.7015 252.415V149.403L28.402 131.815C26.2086 137.26 25 143.197 25 149.403Z" fill="#35476B"></path>
                                <path d="M439.777 102H72.4031C55.3622 102 40.3464 111.113 31.9886 124.706L50.4143 141.487L232.981 299.315C239.499 305.241 247.794 308.203 256.09 308.203C264.268 308.203 272.447 305.324 278.922 299.564L341.624 245C346.923 238.6 346 232.75 343 229.5C337 223 329 225.333 326.25 227.5L263.119 281.423C258.5 284.494 252.905 284.335 248.387 280.947L67.6628 126.294C68.8478 125.702 70.6254 125.702 72.4031 125.702H439.777C441.554 125.702 443.332 125.702 444.517 126.294L369 190.917C366.5 193.111 362.7 199.3 367.5 206.5C369.715 209.333 376.032 213.7 383.573 208.5L461.256 140.909L480.352 124.969C472.027 111.231 456.927 102 439.777 102Z" fill="#35476B"></path>
                            </svg>nkjewels330@gmail.com</p>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Conatct
